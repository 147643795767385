import "tom-select/src/scss/tom-select.scss";
import "intl-tel-input/build/css/intlTelInput.css";

import TomSelect from "tom-select";

const { translations } = theme;

TomSelect.define("add_readonly", function (options) {
	this.hook("after", "sync", function () {
		if (Object.keys(this.options).length < 5) {
			this.control.querySelector("input").setAttribute("readonly", "readonly");
		} else {
			this.control.querySelector("input").removeAttribute("readonly");
		}
	});
});

let defaultSettings = {
	create: false,
	maxItems: 1,
	maxOptions: null,
	// sortField : [{field:'$score'}],
	sortField: function(a, b) {
		const item_a = this.items[a.id];
		const item_b = this.items[b.id];
		
		let value_a = item_a[item_a.sortField ?? 'text'];
		let value_b = item_b[item_b.sortField ?? 'text'];

		return /^\d+$/.test(value_a) && /^\d+$/.test(value_b)
			? (+value_a > +value_b) - (+value_a < +value_b)
			: value_a.localeCompare(value_b);
	},
	render: {
		no_results: function (data, escape) {
			return `<div class="no-results">${translations.nothing_found_for} "${escape(data.input)}"</div>`;
		},
	},
	plugins: {
		clear_button: {
			className:
				"ml-auto self-center text-raven-lighter hover:text-raven-base pointer-events-none hidden [.has-items_&]:block [.has-items_&]:pointer-events-auto",
			title: translations.delete_the_selected_option,
			html: function (data) {
				return `<a href="javascript:void(0)" class="${data.className}" title="${data.title}">
					<svg class="fill-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0462 15.6645C14.4931 16.1114 15.2177 16.1114 15.6647 15.6645C16.1116 15.2175 16.1116 14.4929 15.6647 14.046L11.6186 9.99995L15.6645 5.95409C16.1114 5.50717 16.1114 4.78255 15.6645 4.33563C15.2175 3.8887 14.4929 3.8887 14.046 4.33563L10.0001 8.38148L5.95385 4.3352C5.50692 3.88827 4.78231 3.88827 4.33538 4.3352C3.88845 4.78212 3.88845 5.50673 4.33538 5.95366L8.38167 9.99995L4.3352 14.0464C3.88827 14.4934 3.88827 15.218 4.3352 15.6649C4.78212 16.1118 5.50674 16.1118 5.95366 15.6649L10.0001 11.6184L14.0462 15.6645Z" fill="#111111" fill-opacity="0.48"/>
					</svg>
				</a>`;
			},
		},
		add_readonly: {},
	},
};

function setAutocomleate() {
	document.querySelectorAll("select")?.forEach(function (el) {
		if (
			el.tomselect ||
			el.classList.contains("no-tomselect") ||
			el.parentNode.classList.contains("facetwp-type-fselect")
		) {
			return false;
		}

		let settings = { ...defaultSettings };

		if (el.parentNode.classList.contains("facetwp-facet-sorting")) {
			const translate = {
				"↑": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="M229.7 93.6a8.1 8.1 0 0 1-11.4.1L192 67.3V144a8 8 0 0 1-16 0V67.3l-26.3 26.4a8.2 8.2 0 0 1-11.4 0a8.1 8.1 0 0 1 0-11.4l40-40h.1c.2-.2.4-.3.5-.5l.3-.2l.4-.3l.3-.2l.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2l.3.2l.4.3l.3.2c.2.2.4.3.5.5h.1l40 40a8 8 0 0 1 0 11.3ZM48 136h72a8 8 0 0 0 0-16H48a8 8 0 0 0 0 16Zm0-64h56a8 8 0 0 0 0-16H48a8 8 0 0 0 0 16Zm136 112H48a8 8 0 0 0 0 16h136a8 8 0 0 0 0-16Z"/></svg>',
				"↓": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="m229.7 173.7l-40 40h-.1c-.1.2-.3.3-.5.5l-.3.2l-.4.3l-.3.2l-.3.2h-.4l-.3.2h-.4l-.4.2h-4.6l-.4-.2h-.4l-.3-.2h-.4l-.3-.2l-.3-.2l-.4-.3l-.3-.2l-.5-.5h-.1l-40-40a8.1 8.1 0 0 1 11.4-11.4l26.3 26.4V112a8 8 0 0 1 16 0v76.7l26.3-26.4a8.1 8.1 0 0 1 11.4 11.4ZM120 120H48a8 8 0 0 0 0 16h72a8 8 0 0 0 0-16ZM48 72h136a8 8 0 0 0 0-16H48a8 8 0 0 0 0 16Zm56 112H48a8 8 0 0 0 0 16h56a8 8 0 0 0 0-16Z"/></svg>',
			};
			function replaceIcon(str) {
				for (let i = 0; i < str.length; i++) {
					const char = str[i];
					if (char in translate) {
						const span = document.createElement("span");
						span.innerHTML = translate[char];
						return [span, char];
					}
				}
				return str;
			}

			function optionSortingRender(item, escape) {
				const containerDiv = document.createElement("div");
				const textDiv = document.createElement("div");
				const [iconSvg, char] = replaceIcon(item.text);
				textDiv.className = "capitalize";
				textDiv.textContent = escape(item.text.replace(char, ""));
				containerDiv.className = "flex items-center gap-3";
				containerDiv.appendChild(iconSvg);
				containerDiv.appendChild(textDiv);
				return containerDiv;
			}

			settings.render = {
				option: optionSortingRender,
				item: optionSortingRender,
			};
		}
		if (el.classList.contains("width-icon")) {
			settings.render = {
				option: optionRender,
				item: itemRender,
			};
		}

		const select = new TomSelect(el, settings);
		if (!select.input.querySelector("option[selected]")) {
			select.clear()
		}
	});
}
let intlTelInput = null
const intlTelInputLoaded = new CustomEvent("intlTelInputLoaded")
async function downloadIntlTelInput() {
	if (!intlTelInput) {
		const intlTelInputModule = await import("intl-tel-input");
		intlTelInput = intlTelInputModule.default;
	}
	return intlTelInput;
}

async function downloadIntlUtils() {
	await import("intl-tel-input/build/js/utils")
}

function setIntlTelInput (intlTelInput) {
	document.querySelectorAll('input[name="MobilePhone"]')?.forEach((input) => {
		if (window.intlTelInputGlobals.getInstance(input)) {
			return true;
		}
		const iti = intlTelInput(input, {
			initialCountry: "pl",
			nationalMode: true,
			showSelectedDialCode: true,
			fixDropdownWidth: false,
			formatOnDisplay: true,
			i18n: theme.translations
		});
		input.addEventListener("open:countrydropdown", function() {
			input.previousElementSibling.parentElement.classList.add("open")
		});
		input.addEventListener("close:countrydropdown", function() {
			input.previousElementSibling.parentElement.classList.remove("open")
		});
	});
	document.dispatchEvent(intlTelInputLoaded);
}



if (theme.is_pl) {
	
	document.addEventListener("DOMContentLoaded", async () => {
		// TODO Call here setAutocomleate() and create Custom Event like "TomSelectLoaded"
		downloadIntlTelInput()
			.then((intlTelInput) => {
				setIntlTelInput(intlTelInput)
				downloadIntlUtils()
			})
	})

	document.addEventListener("facetwp-loaded", () => {
		if (intlTelInput) {
			setIntlTelInput(intlTelInput)
		} else {
			downloadIntlTelInput()
				.then((intlTelInput) => {
					setIntlTelInput(intlTelInput)
					downloadIntlUtils().then(() => {
						document.querySelectorAll('input[name="MobilePhone"]')?.forEach((input) => {
							const value = "+" + input.value.replace(/\D/g, '');
							const iti = window.intlTelInputGlobals.getInstance(input);
							const countryData = iti.getSelectedCountryData();
							const phoneStarts = "+" + countryData.dialCode;
							const mask = intlTelInputUtils.getExampleNumber(countryData.iso2, true, intlTelInputUtils.numberType.MOBILE)?.replace(/\d/g, '0')
							const masked = IMask.pipe(value.replace(phoneStarts, ""), {
								mask: mask,
							});
							iti.setNumber(masked);
							if (iti.isValidNumber()) {
								input.parentElement.classList.add("valid");
							}
						})
					})
				})
		}
	});

}
setAutocomleate();
document.addEventListener("facetwp-loaded", () => {
	setAutocomleate();
});

function optionRender(item, escape) {
	const image = item.src
		? `<div class="flex-none">
				<img width="30" loading="lazy" class="mix-blend-multiply" src="${escape(item.src)}" alt="${escape(item.text)}">
			</div>`
		: "";
	const title = item.text
		? `<div class="truncate">
				${escape(item.text)}
			</div>`
		: "";
	const count = item.count
		? `<div class="flex-1 text-right text-sm text-gray-500 [.selected_&]:hidden">
				(${escape(item.count)})
			</div>`
		: "";
	return `
		<div class="flex items-center gap-3">
			${image}
			${title}
		</div>
	`;
}

function itemRender(item, escape) {

	const image = item.src
		? `<div class="flex-none">
				<img width="30" loading="lazy" src="${item.src}" alt="${item.text}">
			</div>`
		: "";
	const title = item.text
		? `<div class="truncate">
				${escape(item.text)}
			</div>`
		: "";
	return `
		<div class="flex items-center gap-3">
			${image}
			${title}
		</div>
	`;
}
